@tailwind base;
@tailwind components;
@import './components.css';

@tailwind utilities;
@import './mega-menu.css';
@import './keen-slider.css';
@import './scroll.css';

:root {
  --gmana-text-base: 17, 24, 39;
  --gmana-bg-base: 255, 255, 255;
  /* indigo.700: #4338ca */
  --gmana-primary: 67, 56, 202;
}

[data-theme='dark'],
.dark {
  --gmana-text-base: 255, 255, 255;
  --gmana-bg-base: 17, 24, 39;
  /* indigo.700: #4338ca */
  --gmana-primary: 67, 56, 202;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  @apply font-sans;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(var(--gmana-bg-base));
  color: rgb(var(--gmana-text-base));
  overscroll-behavior-x: none;
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}
