.mega-menu {
  display: none;
  right: inherit;
  position: absolute;
}

.hoverable {
  position: static;
}

.hoverable > a:after {
  content: '\25BC';
  font-size: 10px;
  padding-left: 6px;
  position: relative;
  top: -1px;
}

.hoverable:hover .mega-menu {
  display: block;
}
